<template>
  <h2 style="color: white;">{{emprendedor.name}}</h2>
  <div uk-grid class=" uk-grid-match">
    <div class="uk-width-1-3@s">
      <div class=" uk-card uk-card-body uk-card-default uk-border-rounded">
        <div uk-grid class=" uk-text-center uk-child-width-1-1 ">
          <div>
            <img v-if="emprendedor.logo" class=" uk-border-rounded" :src="emprendedor.logo" 
                  style="max-height: 250px; object-fit: cover;" alt="">
          </div>
          <div>
            <span style="font-size: 24pt;" ></span>
            <p>
              <span style="font-size: 16pt;">DATOS DE CONTACTO</span><br>
              Tel: {{ emprendedor.tel }} <br>
              Correo: {{ emprendedor.mail }} <br>
            </p>
            <div>
                  <a target="_blank" v-if="emprendedor.wa" :href="'wa.me/'+emprendedor.wa" class="uk-icon-button uk-margin-small-right" uk-icon="whatsapp"></a>
                  <a v-if="emprendedor.fb" :href="emprendedor.fb" class="uk-icon-button uk-margin-small-right" uk-icon="facebook"></a>
                  <a v-if="emprendedor.ins" :href="emprendedor.ins" class="uk-icon-button uk-margin-small-right" uk-icon="instagram"></a>
                  <a v-if="emprendedor.tw" :href="emprendedor.tw" class="uk-icon-button uk-margin-small-right" uk-icon="twitter"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-2-3@s"  >
      <div  class="uk-card uk-card-body uk-card-default uk-border-rounded uk-flex uk-flex-middle">
        <div>
          <h3>PROPÓSITO</h3>
          <p class="uk-text-justify" :style="emprendedor.proposito.length < 550 ? 'font-size:16pt': 'font-size:14pt' ">{{ emprendedor.proposito }}</p>
        </div>
      </div>
    </div>
  </div>
  <h3 style="color: white;">Contribuimos con los ODS:</h3>
      <div class="uk-margin uk-flex uk-flex-center">
        <div v-if="emprendedor.cont1" class=" uk-margin-small-right uk-margin-small-left">
            <img style="width: 109px; height: 115px; object-fit: cover;" class="uk-border-rounded" :src="emprendedor.cont1" alt="">
        </div>
        <div v-if="emprendedor.cont2" class=" uk-margin-small-right uk-margin-small-left">
            <img style="width: 109px; height: 115px; object-fit: cover;" class="uk-border-rounded" :src="emprendedor.cont2" alt="">
        </div>
        <div v-if="emprendedor.cont3" class=" uk-margin-small-right uk-margin-small-left">
            <img style="width: 109px; height: 115px; object-fit: cover;" class="uk-border-rounded" :src="emprendedor.cont3" alt="">
        </div>
        <div v-if="emprendedor.cont4" class=" uk-margin-small-right uk-margin-small-left">
            <img style="width: 109px; height: 115px; object-fit: cover;" class="uk-border-rounded" :src="emprendedor.cont4" alt="">
        </div>
      </div>

  <div tabindex="-1" uk-slider="autoplay: true;autoplay-interval: 0;easing:linear;velocity:0.1;pause-on-hover:false;" class="uk-padding@xl">

<ul class="uk-slider-items uk-grid uk-grid-collapse">
  <li v-for="item of fotos">
      <div class="uk-panel">
          <img style="height: 250px" :src="item" alt="">
      </div>
  </li>
  <li v-for="item of fotos">
      <div class="uk-panel">
          <img style="height: 250px" :src="item" alt="">
      </div>
  </li>
  <li v-for="item of fotos">
      <div class="uk-panel">
          <img style="height: 250px" :src="item" alt="">
      </div>
  </li>
</ul>

</div>

  
  <div class="uk-margin-medium-top  uk-card uk-card-body uk-card-default uk-border-rounded">
    <div uk-grid class="uk-child-width-1-4@s uk-grid-medium">
              <div>
                <h3>MISIÓN</h3>
                <p class="uk-text-justify">{{ emprendedor.mision }}</p>
              </div>
              <div>
                <h3>VISIÓN</h3>
                <p class="uk-text-justify">{{ emprendedor.vision }}</p>
              </div>
            <div>
              <h3>VALORES</h3>
              <ul>
                  <li v-for="(item, index) in emprendedor.valores" :key="index">{{item}}</li>
              </ul>
            </div>
              <div>
                <h3>PRODUCTOS Y SERVICIOS</h3>
                <a :href="emprendedor.catalogo" class="uk-button uk-button-large uk-button-primary uk-border-rounded pulse">VER CATÁLOGO</a>
              </div>
      
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getDatabase, ref as dbref, get } from "firebase/database";
import { useRoute } from 'vue-router';

export default {
  setup(){
    const emprendedor = ref({})
    const route = useRoute()
    const fotos = ref({})
    get(dbref(getDatabase(),'emprendedores/emprendedores/'+route.params.id)).then((s)=>{
      if(s.exists()){
        emprendedor.value = s.toJSON()
        for(let foto of Object.entries(emprendedor.value)){
          if(foto[0].includes('foto')){ 
            fotos.value[foto[0]] = foto[1]
          }
        }
      }
    })
    return {
      emprendedor,
      fotos
    }
  }
}
</script>

<style>

.pulse {
	box-shadow: 0 0 0 0 #005001;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 #005001AA;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px #00500100;
	}
	
	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 #00500100;
	}
}
</style>
